/** Support to React native */

html, body { height: 100%; }
body { overflow: hidden; }
#root { display:flex; height:100%; }

/** Custom fonts */

@font-face {
  font-family: 'MontserratAlternates-Regular';
  src: local('MontserratAlternates-Regular'), url('./assets/fonts/MontserratAlternates-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'MontserratAlternates-Bold';
  src: local('MontserratAlternates-Bold'), url('./assets/fonts/MontserratAlternates-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: local('ProximaNova-Regular'), url('./assets/fonts/ProximaNova-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src: local('ProximaNova-Bold'), url('./assets/fonts/ProximaNova-Bold.ttf') format('truetype');
}

/** Custom style */

.slick-dots {
  position: static;
}

.mapboxgl-ctrl-logo, .mapboxgl-ctrl-attrib {
  display: none !important;
}

.mapboxgl-ctrl-geolocate {
  
}

.mapboxgl-ctrl-geolocate-active {

}

.mapboxgl-ctrl-top-right {
  right: initial;
  left: 25px;
  top: 60px;
}

.slider {
  position: relative;
  overflow: hidden;
}
